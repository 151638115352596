import { render, staticRenderFns } from "./supermarket_details.vue?vue&type=template&id=3b348a0f&scoped=true&"
import script from "./supermarket_details.vue?vue&type=script&lang=js&"
export * from "./supermarket_details.vue?vue&type=script&lang=js&"
import style0 from "./supermarket_details.vue?vue&type=style&index=0&id=3b348a0f&lang=less&scoped=true&"
import style1 from "./supermarket_details.vue?vue&type=style&index=1&lang=less&"
import style2 from "./supermarket_details.vue?vue&type=style&index=2&id=3b348a0f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b348a0f",
  null
  
)

export default component.exports