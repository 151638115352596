<template>
  <div style="background: #fff;color: #000;height: 100%;position: relative;">
    <!-- <div class="my-swipe">
      <van-swipe :autoplay="5000" :loop="false" indicator-color="white">
        <van-swipe-item v-for="(item,idx) in formimg" :key="idx">
          <img class="xq_img" :src="item" />
        </van-swipe-item>
      </van-swipe>
      <van-icon @click="back" class="f_ioc" name="arrow-left" />
    </div> -->
    <van-icon @click="back" class="f_ioc" name="arrow-left" />
    <div class="mianimg">
      <div v-for="(item,idx) in formimg" @click="tupian(item)" :key="idx" style="display: inline-block;">
        <img class="xq_imgs" :src="item" />
      </div>

    </div>

    <div class="xq_h1">
      <div class="xq_h1_a">
        <img src="../../../assets/img/taocimg.png" alt="">
        <span>{{list.name}}</span>
      </div>

      <div class="jiage_A">
        <span class="jin">￥</span>
        <span>{{list.price?list.price.toFixed(2):'0.00'}}</span>
      </div>
    </div>
    <div class="menname">
      <div class="xjfu">
        <span>星级服务</span>
        {{shangjia.name}}
      </div>
      <div style="color: #666;">ID:{{cid?cid:userInfo.id}}</div>
    </div>
    <div class="xq_h2">
      <div class="xq_h3">
        <div class="xuan_a gsdz">
          <span class="diz">服务范围：</span>
          <span class="dz">
            <span v-for="(item,idxs) in mdlist.businessScope" :key="idxs">{{item}}</span>
          </span>
        </div>
        <div class="xuan_a">
          <span class="diz">营业时间：</span>
          <span>{{mdlist.tradeTime}}-{{mdlist.restTime}}</span>
        </div>
        <div class="xuan_a gsdz">
          <span class="diz">公司地址：</span><span class="dz">{{shangjia.city}}{{shangjia.district}}{{shangjia.address}}</span>
        </div>
      </div>
      <div class="dh">
        <a class="daohan" @click="todaohang">
          <van-icon size="18" color="#000" name="guide-o" />
          <div>导航</div>
        </a>
        <a :href="'tel:'+shangjia.tel1">
          <van-icon size="18" color="#000" name="phone-o" />
          <div>电话</div>
        </a>
      </div>
      <!-- <div class="xuan_a" style="margin-top: 16px;">
        <span class="xin_c left" style="line-height: 13px;">门店</span>
        <span class="xin_b left">
          <img class="mendiantu" v-if="shangjia.doorPic" :src="shangjia.doorPic" alt />
          <img class="mendiantu" v-else src="../../../assets/img/mendian.png" alt />
        </span>
        <div class="left dian_ha">
          <div class="dian_h1">{{shangjia.name}}</div>
          <div class="dian_h2">
            {{juli}}km
            <span>免运费</span>
          </div>
          <div class="dian_h3">{{shangjia.city}}{{shangjia.district}}{{shangjia.address}}</div>
        </div>
      </div> -->
    </div>
    <div class="tanan_xq">套餐卡详情</div>

    <div class="list">
      <div class="taocan_list" v-for="(item,idx) in list.activityItems" :key="idx">
        <div v-for="(is,ix) in item.items" :key="ix" class="mians">
          <div class="namesimg">
            <img class="tao_che" src="../../../assets/img/taocan_che.png" alt />
            <span class="">{{is.itemName}}</span>
          </div>
          <div class="">
            <div class="list_a">
              <span class="right" v-if="is.totalTimes>99">不限次</span>
              <span class="right" v-else>{{is.totalTimes}}次</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tanan_xq">购买须知</div>
    <div class="shuoming">
      <p>1.本套餐卡仅支持 {{shangjia.name}} 使用，其它类型门店概不支持；</p>
      <p>2.本套餐自购买之日起12月内有效；</p>
      <p>3.本套餐不与 {{shangjia.name}} 线上其他同期优惠活动叠加使用</p>
      <p>4.本套餐包含优惠服务，不提供优先、插队等特权，请用户提前与门店沟通安排行程，如需排队请耐心等候；</p>
      <p v-if="!list.limitCarNo">5.此套餐卡不绑定车牌，可以和朋友车辆共享服务；</p>
      <p v-if="list.limitCarNo">5.此套餐卡绑定车牌，限制当前车牌号使用；</p>
      <p>6.本活动最终解释权归{{shangjia.name}}；</p>
    </div>
    <div style="height:75px;"></div>
    <van-goods-action>
      <!-- <van-goods-action-icon icon="service-o" text="在线客服" @click />
      <van-goods-action-icon icon="star-o" text="收藏" @click /> -->
      <van-goods-action-button color="linear-gradient(to right, #ecbe5d, #c38343)" text="立即购买" v-if="payisok" @click="onClickButton" />
      <van-goods-action-button color="linear-gradient(to right, #ecbe5d, #c38343)" text="已购买，查看详情" v-else @click="showpay = true" />
    </van-goods-action>
    <van-popup class="paydiv" closeable v-model="showpay">
      <div class="h1">
        <img src="../../../assets/img/goushigong.png" alt="">
        购买成功
      </div>
      <div class="tan_1">消费时请出示以下券码或自行注册，到门店即可使用</div>
      <div class="qy_code_wrapper">
        <vue-qr :text="downloadData.url" :margin="0" colorDark="#000" colorLight="#fff" :dotScale="1" :logoSrc="downloadData.icon" :logoScale="0.2" :size="160"></vue-qr>
        <div>
          <span style="color: #666;">兑换码：</span>
          <span>{{formpay.orderNumber}}</span>
          <span class="fz" @click="copy">复制</span>
        </div>
      </div>
      <div class="mian_b">
        <div class="kaname">{{formpay.serviceName}}</div>
        <div><span class="gous">购买日期</span><span>{{formpay.createTime}}</span></div>
        <div><span class="gous">使用方法</span><span>关注驿车驹公众号，添加车辆，在下边兑换栏点击兑换</span></div>
      </div>
      <div class="tanan_xq">套餐卡详情</div>
      <div class="list" style="border-bottom:none;">
        <div class="taocan_list" v-for="(item,idx) in list.activityItems" :key="idx">
          <div v-for="(is,ix) in item.items" :key="ix" class="mians">
            <div class="namesimg">
              <img class="tao_che" src="../../../assets/img/taocan_che.png" alt />
              <span class="">{{is.itemName}}</span>
            </div>
            <div class="">
              <div class="list_a">
                <span class="right" v-if="is.totalTimes>99">不限次</span>
                <span class="right" v-else>{{is.totalTimes}}次</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  onlineDetails,
  getmembername,
  garageGetGarageInfo,
  getmembercombo,
  defaultcarInfo,
  getGarageInfo,
  config,
} from "../../../api/check.js";
import { ImagePreview } from "vant";
import vueQr from "vue-qr";
// import userModel from "../../../api/user";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    vueQr,
  },
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      jd: JSON.parse(localStorage.getItem("JD")),
      wd: JSON.parse(localStorage.getItem("WD")),
      list: {},
      payisok: true,
      showpay: false,
      mdlist: {},
      formpay: {},
      downloadData: {},
      formimg: [],
      active: "0",
      value: 1,
      Aswitch: 1,
      gid: "",
      cid: "",
      tcid: 0,
      juli: 0,
      id: "",
    };
  },
  methods: {
    qiehuan(row) {
      this.active = row;
    },
    //复制链接成功
    copy() {
      const input = document.createElement("input");
      document.body.appendChild(input);
      var http = this.formpay.orderNumber;
      input.setAttribute("value", http);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");

        this.$toast.success("复制成功！");
      }
      document.body.removeChild(input);
    },
    onClickButton() {
      var item = this.list;
      this.$router.push({
        path: "ka_pay",
        query: {
          isok: true,
          cid: this.$route.query.cid,
        },
      });
      this.list.type = 17;
      this.list.activityPrice = item.price;
      this.list.activityUrl = item.image;
      this.list.name = item.name;
      sessionStorage.setItem("huiyuan", JSON.stringify(this.list));
    },
    //获取车辆信息
    getmodel() {
      //
      defaultcarInfo().then((e) => {
        console.log(e.data);
        if (e.data == undefined) {
          sessionStorage.setItem("chexin", null);
        } else {
          this.chexin = e.data;
          sessionStorage.setItem("chexin", JSON.stringify(e.data));
        }
        // if(e.data)
      });
    },
    getlistimg() {
      garageGetGarageInfo()
        .then((e) => {
          this.formimg = e.data.storeEnvironment;
          this.mdlist = e.data;
        })
        .catch();
    },
    //导航
    todaohang() {
      var isWxMini = window.__wxjs_environment === "miniprogram";
      if (isWxMini) {
        var data = {
          latitude: this.shangjia.positionWd,
          longitude: this.shangjia.positionJd,
          name: this.shangjia.name,
        };
        let payDataStr = JSON.stringify(data);
        wx.miniProgram.navigateTo({
          url: "/pages/map/map?payDataStr=" + payDataStr, // 微信小程序调用支付的页面
        });
      } else {
        var positionWd = Number(this.shangjia.positionWd);
        var positionJd = Number(this.shangjia.positionJd);
        wx.openLocation({
          latitude: positionWd, // 纬度，浮点数，范围为90 ~ -90
          longitude: positionJd, // 经度，浮点数，范围为180 ~ -180。
          name: this.shangjia.name, // 位置名
          address: "", // 地址详情说明
          scale: 13, // 地图缩放级别,整形值,范围从1~28。默认为最大
          infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
        });
        return;
      }
    },
    tupian(item) {
      // ImagePreview({[{item}],closeable: true,});
      ImagePreview({
        images: [item],
        closeable: true,
      });
    },
    onLoad() {
      var _this = this;
      const data = {
        id: this.id,
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      getmembercombo(data)
        .then((e) => {
          loading.clear();
          this.list = e.data;
          _this.$nextTick(() => {
            _this.getConfig();
          });
          var isWxMini = window.__wxjs_environment === "miniprogram";
          if (isWxMini) {
            var link =
              "https://shopping.car-posthouse.cn/supermarket_details?gid=" +
              this.gid +
              "&cid=" +
              this.userInfo.id +
              "&id=" +
              this.id +
              "&denglu=" +
              true;
            wx.miniProgram.postMessage({
              data: {
                url: link,
              },
            });
          }
          this.payname();
        })
        .catch(() => loading.clear());
    },
    //是否购买
    payname() {
      var data = {
        id: this.list.id,
        type: 17,
      };
      getmembername(data)
        .then((e) => {
          if (e.data) {
            this.formpay = e.data;
            this.downloadData = { 
              url:
                "http://customer.car-posthouse.cn/quanbu_details.html?id=" +
                e.data.id +
                "&type=" +
                e.data.type, //需要转化成二维码的网址
              icon: require("../../../assets/img/1.png"), //二维码中间的图片,可以不设置
            };
            if (this.$route.query.xcxisok == "true") {
              this.showpay = true;
            }
            this.payisok = false;
          } else {
            this.payisok = true;
          }
        })
        .catch();
    },
    getConfig() {
      let that = this;
      let userAgent = navigator.userAgent
      let shareUrl ='';
      if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
          shareUrl = sessionStorage.getItem('originUrl')
      }else {
          shareUrl = location.href
      }
      config({
        url: shareUrl,
        gid: sessionStorage.getItem("Agid")
          ? sessionStorage.getItem("Agid")
          : 208,
      }).then((res) => {
        if (res.code == 200) {
          console.log(res);
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appid, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: ["updateAppMessageShareData", "openLocation"],
          });
          var link =
            "https://shopping.car-posthouse.cn/supermarket_details?gid=" +
            that.gid +
            "&cid=" +
            that.userInfo.id +
            "&id=" +
            that.id +
            "&denglu=" +
            true;
          console.log(link);
          var desc =
            that.userInfo.realName + "已参与活动，邀你一起参与共享优惠";
          wx.ready(() => {
            wx.updateAppMessageShareData({
              title: that.list.name,
              desc: "东莞黄江驿车驹·宏开店优惠套餐超市",
              link: link,
              imgUrl: that.list.image
                ? that.list.image
                : "http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/3494/163877524258233.png?Expires=1954135242&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=PwQzEWBL5mt69kChKWmzeVhAFf8%3D",
              success: function () {},
            });
          });
        } else {
          that.$toast(res.data.msg);
        }
      });
    },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "list"
      // });
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.gid = this.$route.query.gid;
    this.cid = this.$route.query.cid;
    if (localStorage.getItem("Authorization") == null) {
      // if (this.$route.query.denglu != undefined) {
      var url =
        this.$route.path +
        "?gid=" +
        this.$route.query.gid +
        "&cid=" +
        this.$route.query.cid +
        "&id=" +
        this.$route.query.id;
      localStorage.setItem("beforeUrl", url);
      sessionStorage.setItem("Agid", this.$route.query.gid);
      sessionStorage.setItem("gid", this.$route.query.gid);
      sessionStorage.setItem("cid", this.$route.query.cid);
      sessionStorage.setItem("id", this.$route.query.id);
      getGarageInfo({ gid: this.$route.query.gid })
        .then((e) => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        })
        .catch(() => loading.clear());
      return;
    } else {
      getGarageInfo({ gid: this.$route.query.gid })
        .then((e) => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
        })
        .catch(() => loading.clear());
      this.getmodel();
    }

    this.onLoad();
    this.getlistimg();
    // console.log(this.$route.query);
    if (JSON.parse(localStorage.getItem("JD")) != null) {
      var radLat1 = (this.wd * Math.PI) / 180.0;
      var radLat2 = (this.shangjia.positionWd * Math.PI) / 180.0;
      var a = radLat1 - radLat2;
      var b =
        (this.jd * Math.PI) / 180.0 -
        (this.shangjia.positionJd * Math.PI) / 180.0;
      var s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * 6378.137; // EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000;
      this.juli = s.toFixed(2);
    }
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.mianimg {
  // padding-top: 4px;
  white-space: nowrap;
  height: 130px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding-left: 10px;
  .xq_imgs {
    width: 220px;
    height: 130px;
    display: block;
    margin-right: 8px;
    border-radius: 6px;
  }
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.ord_lis .order_list:nth-of-type(n + 2) {
  margin-top: 10px;
}

.list {
  border-bottom: 8px solid #f5f5f5;
  font-size: 14px;
  padding: 0 12px 10px 12px;
  .list_a {
    overflow: hidden;
  }
  .namesimg {
    display: flex;
    align-items: center;
  }
  .list_b {
    font-size: 12px;
    color: #666;
    margin-top: 8px;
    border-bottom: 1px dotted #f5f5f5;
    padding-bottom: 6px;
  }

  .tao_che {
    width: 22px;
    margin-right: 5px;
  }
}
.tanan_xq {
  line-height: 34px;
  color: #999;
  font-size: 12px;
  border-bottom: 1px solid #f5f5f5;
  margin-left: 12px;
}
.shuoming {
  font-size: 12px;
  padding: 12px 12px 0 12px;
  color: #666;
}
.shuoming p {
  line-height: 20px;
}
.my-swipe {
  position: relative;
  margin-right: 12px;
  //   height: 362px;
}
.van-swipe-item {
  margin-left: 10px;
}
.xq_img {
  width: 80%;
  border-radius: 10px;
  // height: 300px;
  // width: 300px;

  // margin: auto;
}
.mians {
  line-height: 20px;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  .mians_a {
    width: 70%;
    font-weight: bold;
  }
}
.van-swipe-item {
  display: flex;
}
.xqbeij {
  transform: translateZ(0);
  // position: absolute;

  // left: 0;
  width: 100%;
  //   height: 40px;
  // z-index: 99;
}
.f_ioc {
  position: absolute;

  top: 0;
  left: 0;
  z-index: 99;
  margin-top: 10px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  background-color: rgba(31, 45, 61, 0.3);
  border-radius: 50%;
}
.canpin {
  padding: 0 15px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
}
.jiage {
  font-size: 14px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  height: 57px;
  display: flex;
  align-items: center;
  margin-left: 15px;
  // margin-top: 12px;
  font-size-adjust: 0.58;
  font-family: Times;
}
.jin {
  font-size: 12px;
}
.jiage_A {
  font-size: 23px;
  font-weight: bold;
  line-height: 14px;
  color: #ef4136;
}
.shijian {
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  margin-top: 8px;
  text-align: center;
}
.xq_h1 {
  // background: linear-gradient(to right, #ecbe5d, #c38343);
  background: #e3c175;
  background: rgba(227, 193, 117, 0.1);
  padding: 12px 12px;
  // border-bottom: 8px solid #f5f5f5;
  // margin-top: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-radius: 4px;
}
.menname {
  font-size: 16px;
  padding: 8px 10px 0 10px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // font-weight: bold;
  .xjfu {
    display: flex;
    align-items: center;
  }
  span {
    margin-right: 6px;
    display: flex;
    align-items: center;
    height: 18px;
    margin-top: 2px;
    font-size: 12px;
    padding: 0 3px;
    font-weight: normal;
    // line-height: 16px;
    color: #f23756;
    transform: scale(0.9);
    border-radius: 2px;
    border: 1px solid #f23756;
  }
}
.xq_h2 {
  padding: 0px 15px 15px 15px;
  border-bottom: 8px solid #f5f5f5;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .xq_h3 {
    width: 280px;
  }
  .gsdz {
  }
  .xuan_a {
    display: flex;
    font-size: 12px;
    overflow: hidden;
    line-height: 18px;
    color: #000;
    margin-top: 10px;
    span {
      display: inline-block;
    }
    .dz {
      // min-width: 80px;
      width: 200px;
    }
  }
  .dh {
    display: flex;
    align-items: center;
    font-size: 12px;
    .daohan {
      margin-right: 10px;
    }
    a {
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #666;
      .van-icon {
        display: block;
        margin: auto;
      }
    }
  }
}

.ciri {
  font-size: 10px;
  background: #c9e5f6;
  border-radius: 2px;
  border: 0.5px solid #87bcda;
  color: #309eda;
  padding: 1px 3px;
  margin-right: 5px;
  line-height: 10px;
  display: inline-block;
}
.mendiantu {
  width: 80px;
  height: 55px;
  border-radius: 4px;
}
.dian_h1 {
  font-size: 15px;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
}
.dian_h3 {
  color: #666;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.van-goods-action-button--last {
  border-radius: 20px;
}
.dian_h2 {
  color: #666;
  line-height: 14px;
  margin-top: 8px;
}
.dian_ha {
  margin-left: 10px;
  width: 210px;
}

.yixuan {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}
.xq_h1_a {
  line-height: 16px;
  font-size: 19px;
  font-weight: bold;
  width: 70%;
  display: flex;
  align-items: center;

  img {
    width: 26px;
    margin-right: 8px;
  }
}
.qianggou {
  display: inline-block;
  background: #f1dadc;
  color: #f23756;
  line-height: 14px;
  padding: 2px 3px;
  margin-left: 8px;
  border-radius: 2px;
  font-size: 13px;
}
.jian {
  display: inline-block;
  background: #fff;
  color: #f23756;
  // padding: 0 2px;
  width: 18px;
  text-align: center;
  border-radius: 2px;
}
.shi_b {
  line-height: 14px;
}
.jiage_b {
  line-height: 14px;
}
.xqbeij img {
  width: 100%;
}
.ql-editor {
  margin: 2px 15px;
  overflow: hidden;
  font-size: 14px;
  // margin-top: 15px;
}
.paydiv {
  width: 100%;
  height: 100%;
  font-size: 12px;
  .h1 {
    justify-content: center;
    font-size: 18px;
    padding: 20px 0 15px 0;
    font-weight: bold;
    display: flex;
    align-items: center;
    img {
      width: 22px;
      margin-right: 10px;
    }
  }
  .tan_1 {
    font-size: 12px;
    text-align: center;
  }
  .qy_code_wrapper {
    // width: 80%;
    // margin: auto;
    text-align: center;
    padding: 15px 0 8px 0;
    border-bottom: 1px solid #ebedf0;
    .fz {
      margin-left: 10px;
      color: #0d906e;
    }
  }
  .mian_b {
    padding: 0 0 0 14px;
    .kaname {
      font-size: 16px;
      padding: 10px 0 5px 0;
    }
    div {
      padding: 5px 0;
    }
    .gous {
      color: #999;
      margin-right: 15px;
    }
  }
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
.ql-editor p img {
  width: 100%;
  outline-width: 0px;
  vertical-align: top;
}
</style>
<style scoped>
.msgHtmlBox >>> img {
  width: 100%;
}
/* .msgHtmlBox >>> strong {
  font-weight: bold;
} */
</style>